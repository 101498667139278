<template>
  <div>
    <ReportCenterTitle title="instructorRevenues" />
  </div>
</template>

<script>
import ReportCenterTitle from "../Title.vue";
export default {
  components: {
    ReportCenterTitle,
  },
};
</script>

<style></style>
