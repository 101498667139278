<template>
  <div>
    <InstructorRevenues />
  </div>
</template>

<script>
import InstructorRevenues from "../components/ReportCenter/InstructorRevenues";
export default {
  components: {
    InstructorRevenues,
  },
};
</script>

<style></style>
